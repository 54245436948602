import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },
  paperCards: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Sermons() {
  const classes = useStyles();

  const IFrameSetup = (url) => {
    return (
      <iframe
        title="Loading...."
        frameBorder="0"
        src={url}
        enablecastapi="true"
        allowfullscreen="true"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      >
        Loading .....
      </iframe>
    );
  };

  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom align="center">
        Sermons
      </Typography>
      <div className={classes.root}>
        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Pst Gregory
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom align="center">
            Date : 03 May 2020
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            {IFrameSetup("https://www.youtube.com/embed/jfFN-syXk2I")}
          </Typography>
        </Paper>
        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Pst Gregory
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom align="center">
            Date : 26 April 2020
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            {IFrameSetup("https://www.youtube.com/embed/5tWxhyIDpZY")}
          </Typography>
        </Paper>
        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Pst Gregory - Good Friday
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom align="center">
            Date : 10 April 2020
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            {
              <iframe
                src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F255491817968821%2Fvideos%2F528825648066494%2F&show_text=0&width=267"
                width="267"
                height="476"
                frameBorder="0"
                scrolling="no"
                frameborder="0"
                allowTransparency="true"
                allowFullScreen="true"
              ></iframe>
            }
          </Typography>
        </Paper>
        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Pst Gregory
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom align="center">
            Date : 05 April 2020
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            <audio controls>
              <source
                src="http://patmos.businessprovider.co.za/test.mp3"
                type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Pst Gregory
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom align="center">
            Date : 15 March 2020
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            <audio controls>
              <source
                src="http://patmos.businessprovider.co.za/15-03-2020.mp3"
                type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Pst Gregory
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom align="center">
            Date : 8 March 2020
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            <audio controls>
              <source
                src="http://patmos.businessprovider.co.za/08-03-2020.mp3"
                type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Pst Gregory
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom align="center">
            Date : 29 Feb 2020
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            <audio controls>
              <source
                src="http://patmos.businessprovider.co.za/29-02-2020.mp3"
                type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Pst Gregory
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom align="center">
            Date : 23 Feb 2020
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            <audio controls>
              <source
                src="http://patmos.businessprovider.co.za/23-02-2020.mp3"
                type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Pst Gregory
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom align="center">
            Date : 16 Feb 2020
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            <audio controls>
              <source
                src="http://patmos.businessprovider.co.za/16-02-2020.mp3"
                type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
          </Typography>
        </Paper>
      </div>
    </>
  );
}
